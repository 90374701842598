<template>
  <dog-dialog
    ref="dogDialog"
    width="800px"
    title="编辑公司"
    @loading-confirm="confirm"
    @closed="closed"
  >
    <dog-form
      ref="dogForm"
      :form-data="formData"
      :form-options="formOptions"
      :column-num="2"
      :col-rule="(row) => row.col || 12"
    ></dog-form>
  </dog-dialog>
</template>

<script>
  import companyService from '@/api/service/company.js';
  import batchImgUpload from '@/components/batchImgUpload.vue';

  export default {
    name: 'editCompanyDialog',
    data() {
      return {
        formData: {},
        formOptions: [
          {
            type: 'input',
            formItem: {
              label: '公司名',
              prop: 'name',
              rules: [this.$formRules.required('请输入公司名')]
            },
            attrs: {
              disabled: true
            }
          },
          {
            type: 'select',
            formItem: {
              label: '类型',
              prop: 'type',
              rules: [this.$formRules.required('请选择类型')]
            },
            attrs: {
              service: this.$enumService('companyType'),
              clearable: false,
              disabled: true
            }
          },
          {
            type: 'input',
            formItem: {
              label: '联系人',
              prop: 'contact',
              rules: [this.$formRules.required('请输入联系人')]
            }
          },
          {
            type: 'input',
            formItem: {
              label: '联系方式',
              prop: 'phone',
              rules: [this.$formRules.required('请输入联系方式')]
            }
          },
          {
            type: 'input',
            formItem: {
              label: '地址',
              prop: 'address',
              rules: [this.$formRules.required('请输入地址')]
            }
          },
          {
            type: 'input',
            formItem: {
              label: '电子邮箱',
              prop: 'email',
              rules: [this.$formRules.email]
            }
          },
          {
            type: 'custom',
            formItem: {
              prop: 'mainImg',
              label: '主图',
              rules: [this.$formRules.required('请上传主图')]
            },
            component: batchImgUpload,
            col: 24
          },
          {
            type: 'input',
            formItem: {
              label: '简介',
              prop: 'desc',
              rules: []
            },
            attrs: {
              type: 'textarea',
              rows: 3
            },
            col: 24
          }
        ]
      };
    },
    methods: {
      openFrame(id) {
        this.$refs.dogDialog.open();
        if (id) {
          companyService.getCompanyDetail({ id }).then((res) => {
            this.formData = res;
          });
        }
      },
      confirm(done) {
        this.$refs.dogForm
          .validate()
          .then(() => {
            if (this.formData.mainImg.length > 10) {
              this.$message({
                type: 'error',
                message: '最多只能添加 10张 主图！'
              });
              return Promise.reject();
            }
            return companyService.editCompany(this.formData);
          })
          .then(() => {
            this.$message.success('保存成功！');
            done();
            this.$emit('confirm');
            this.close();
          })
          .catch(done);
      },
      close() {
        this.$refs.dogDialog.close();
      },
      closed() {
        this.formData = {};
      }
    }
  };
</script>

<style lang="scss" scoped></style>
